<template>
  <div class="admin-a2p-detail">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loading">
              <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="brandForm">
                <form @submit.prevent="handleSubmit(onUpdateClick)">
                  <div class="d-flex justify-content-between">
                    <h5 class="h5">Campaign Detail #{{ data.id }}</h5>
                    <b-button variant="warning" @click="onBack">
                      <span><i class="uil uil-arrow-left"></i> Back</span>
                    </b-button>
                  </div>
                  <div class="d-block">
                    <div v-if="brandStatus === 'unverified'">
                      <h5 class="h5 text-primary d-flex align-items-center"><strong class="mx-2 text-danger"><i>Decline -
                            Brand</i></strong>
                      </h5>
                    </div>
                    <div v-else-if="brandStatus">
                      <h5 class="h5 d-flex align-items-center"><strong :class="{ 'text-danger': isCampaignFailed }"
                          class="text-primary"><i>{{ campaignStatusStr
                            | capitalize }}</i></strong><b-spinner v-if="campaignInProgress"
                          class="text-primary ml-2"></b-spinner></h5>
                      <span v-if="isCampaignFailed && failedReason" class="d-blcok text-danger font-weight-bold">{{
                        failedReason }}</span>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <TextInput v-model="brand.name" name="name" rules="required" label="DBA or Brand Name" sublabel="*"
                        :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider v-slot="{ failed, errors }" name="sms_org_form" rules="required">
                        <label>What type of legal form is the org? <span class="text-danger">*</span></label>
                        <multiselect v-model="orgForm" :allow-empty="true" :options="orgForms" :multiple="false"
                          :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                          :disabled="isFormDisabled"></multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.company_name" name="company_name" rules="required"
                        label="Legal Company Name" sublabel="*" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.ein" name="ein" rules="required" label="Tax Number/ID/EIN" sublabel="*"
                        :disabled="isFormDisabled" @changed="onEinChange" />
                    </div>
                    <div class="col-md-6">
                      <PhoneInput v-model="brand.phone" name="phone" label="Phone" sublabel="*" rules="required|phone"
                        :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.email" type="email" name="email" rules="required|email" label="Email"
                        sublabel="*" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.address" name="address" rules="required" label="Address" sublabel="*"
                        :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.city" name="city" rules="required" label="City" sublabel="*"
                        :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6 mb-3">
                      <ValidationProvider v-slot="{ failed, errors }" name="sms_country" rules="required">
                        <label>Country <span class="text-danger">*</span></label>
                        <multiselect v-model="country" :allow-empty="true" :options="countries" :multiple="false"
                          :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                          :disabled="isFormDisabled"></multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider v-slot="{ failed, errors }" name="sms_state" rules="required">
                        <label>State <span class="text-danger">*</span></label>
                        <multiselect v-model="state" :allow-empty="true" :options="states" :multiple="false"
                          :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                          :disabled="isFormDisabled"></multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.zip" type="number" name="zip" rules="required" label="Zip Code"
                        sublabel="*" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <ValidationProvider v-slot="{ failed, errors }" name="sms_vertical" rules="required">
                        <label>Vertical Type <span class="text-danger">*</span></label>
                        <multiselect v-model="vertical" :allow-empty="true" :options="verticals" :multiple="false"
                          :class="{ 'is-invalid': failed }" track-by="value" label="label" :show-labels="false"
                          :disabled="isFormDisabled"></multiselect>
                        <b-form-invalid-feedback v-if="errors.length">
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="brand.website" name="website" label="Website" :disabled="isFormDisabled" />
                    </div>
                  </div>
                  <h5 class="h5">Campaign Attributes</h5>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Campaign <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name=" ">
                          <textarea v-model="campaign.desc" name="  " class="form-control" rows="4"
                            :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Message Flow <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name="messageFlow">
                          <textarea v-model="campaign.message_flow" name="messageFlow" class="form-control" rows="4"
                            :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div v-for="index in 2" :key="index" class="col-md-6">
                      <div class="form-group">
                        <label>Sample {{ index }} <span v-if="index === 1" class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" :rules="index === 1 ? `required` : ``"
                          :name="`sample${index}`">
                          <textarea v-model="campaign[`sample_${index}`]" :name="`sample${index}`" class="form-control"
                            rows="3" :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-6"></div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-6">
                      <TextInput v-model="campaign.optin_keyword" name="optinKeyword"
                        label="Subscriber Opt-In Keywords" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscriber Opt-In Message</label>
                        <ValidationProvider v-slot="{ failed, errors }" name="optinMessage">
                          <textarea v-model="campaign.optin_message" name="optinMessage" class="form-control" rows="2"
                            :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="campaign.optout_keyword" name="optoutKeyword"
                        label="Subscriber Opt-Out Keywords" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscriber Opt-Out Message <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name="optoutMessage">
                          <textarea v-model="campaign.optout_message" name="optoutMessage" class="form-control" rows="2"
                            :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="campaign.help_keyword" name="Subscriber Help Keywords."
                        label="Subscriber Help Keywords" :disabled="isFormDisabled" />
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscriber Help Message <span class="text-danger">*</span></label>
                        <ValidationProvider v-slot="{ failed, errors }" rules="required" name="helpMessage">
                          <textarea v-model="campaign.help_message" name="helpMessage" class="form-control" rows="2"
                            :class="{ 'is-invalid': failed }" :disabled="isFormDisabled"></textarea>
                          <b-form-invalid-feedback v-if="errors.length">
                            {{ errors[0] }}
                          </b-form-invalid-feedback>
                        </ValidationProvider>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="radio-wrapper">
                        <label>Will more than 50 TNs be used for this campaign?</label>
                        <div class="form-group text-center radio-group">
                          <b-form-radio v-model="campaign.number_pool" name="number_pool" :value="true" class="d-inline"
                            :disabled="true">Yes</b-form-radio>
                          <b-form-radio v-model="campaign.number_pool" name="number_pool" :value="false"
                            class="d-inline ml-4" :disabled="true">No</b-form-radio>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="radio-wrapper">
                        <label>Will the campaign include content related to direct lending or other loan
                          arrangements?</label>
                        <div class="form-group text-center radio-group">
                          <b-form-radio v-model="campaign.direct_lending" name="direct_lending" :value="true"
                            class="d-inline" :disabled="true">Yes</b-form-radio>
                          <b-form-radio v-model="campaign.direct_lending" name="direct_lending" :value="false"
                            class="d-inline ml-4" :disabled="true">No</b-form-radio>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="radio-wrapper">
                        <label>Are you using an embedded link of any kind?</label>
                        <div class="form-group text-center radio-group">
                          <b-form-radio v-model="campaign.embedded_link" name="embedded_link" :value="true"
                            class="d-inline" :disabled="true">Yes</b-form-radio>
                          <b-form-radio v-model="campaign.embedded_link" name="embedded_link" :value="false"
                            class="d-inline ml-4" :disabled="true">No</b-form-radio>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="radio-wrapper">
                        <label>Are you using any embedded phone number beyond the contact number in the HELP
                          response?</label>
                        <div class="form-group text-center radio-group">
                          <b-form-radio v-model="campaign.embedded_phone" name="embedded_phone" :value="true"
                            class="d-inline" :disabled="true">Yes</b-form-radio>
                          <b-form-radio v-model="campaign.embedded_phone" name="embedded_phone" :value="false"
                            class="d-inline ml-4" :disabled="true">No</b-form-radio>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 mt-4">
                      <div class="radio-wrapper">
                        <label>Will the campaign include any age-gated content as defined by carrier and CTIA
                          guidelines?</label>
                        <div class="form-group text-center radio-group">
                          <b-form-radio v-model="campaign.age_gated" name="age_gated" :value="true" class="d-inline"
                            :disabled="true">Yes</b-form-radio>
                          <b-form-radio v-model="campaign.age_gated" name="age_gated" :value="false" class="d-inline ml-4"
                            :disabled="true">No</b-form-radio>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12 text-center mt-5">
                    <b-form-group>
                      <b-button variant="warning" @click="onBack">
                        <span><i class="uil uil-arrow-left"></i> Back</span>
                      </b-button>
                      <b-button variant="primary" :disabled="processing || (invalid && touched) || !canSubmitCampaign"
                        type="submit" class="ml-2">
                        <b-spinner v-if="processing" small />
                        <span v-else>Update</span>
                      </b-button>
                    </b-form-group>
                  </div>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <b-modal v-model="showEinModal" title="Confirmation">
        <p>We found a brand with same <b>EIN ( {{ brand.ein }} )</b> is already registered. Are you going to use
          existing brand for your registration process?</p>
        <template v-slot:modal-footer>
          <b-button variant="primary" @click="onEinConfirm">Yes</b-button>
          <b-button variant="outline-dark" @click="onEinCancel">No</b-button>
        </template>
      </b-modal>
      <b-modal v-model="showConfirmModal" no-close-on-backdrop hide-header-close>
        <template v-slot:modal-title>
          <h5 class="text-primary">Confirmation</h5>
        </template>
        <p>Are you sure information you put below is accurate?</p>
        <template v-slot:modal-footer>
          <b-button variant="light" @click="showConfirmModal = false">Cancel</b-button>
          <b-button variant="primary" @click="onUpdate">Submit</b-button>
        </template>
      </b-modal>
    </div>
  </div>
</template>
  
<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import _ from 'lodash';

export default {
  components: {
    Multiselect,
  },

  data() {
    return {
      loading: false,
      processing: false,
      data: {
        brand: {},
        campaign: {},
      },
      brand: {},
      campaign: {},
      orgForms: [
        { value: 'PRIVATE_PROFIT', label: 'Privately Held Company' },
        { value: 'PUBLIC_PROFIT', label: 'Publicly Traded Company' },
        { value: 'NON_PROFIT', label: 'Nonprofit Organization' },
        { value: 'GOVERNMENT', label: 'Government' },
      ],
      orgForm: undefined,
      country: undefined,
      state: undefined,
      vertical: undefined,
      einData: undefined,
      showEinModal: false,
      useEinData: false,
      isNew: true,
      showConfirmModal: false,
    }
  },

  computed: {
    ...mapGetters('business', {
      countries: 'countriesBw',
      states: 'statesBw',
      verticals: 'verticalsBw',
    }),

    user() {
      return this.$store.getters['auth/user']
    },

    brandStatus() {
      if (this.data && this.data.brand_status === 'in-progress')
        return this.data.brand_status
      return this.data && this.brand && this.brand.status
    },

    campaignStatus() {
      return this.data && this.data.main_status
    },

    campaignStatusStr() {
      if (this.campaignStatus === 'declined')
        return 'Decline - Campaign'
      else if (this.campaignStatus === 'expired')
        return 'Deactivated'
      else if (this.campaignStatus === 'completed')
        return 'Verified & Approved'
      else
        return this.data && this.data.main_status_str
    },

    canSubmitCampaign() {
      return this.campaignStatus !== 'completed'
    },

    campaignInProgress() {
      return this.campaignStatus && !['completed', 'expired', 'declined'].includes(this.campaignStatus)
    },

    isCampaignDeclined() {
      return this.campaignStatus === 'expired'
    },
    
    isCampaignFailed() {
      return ['expired', 'declined'].includes(this.campaignStatus)
    },

    failedReason() {
      return this.campaign && this.campaign.failed_reason
    },

    isFormDisabled() {
      return this.campaignStatus === 'completed'
    },
  },

  mounted() {
    let id = this.$route.params.id
    if (id) {
      this.getRequestDetail(id)
    } else {
      this.$router.push({ name: 'admin.settings.a2p' })
    }
  },

  methods: {
    onBack() {
      this.$router.push({ name: 'admin.settings.a2p' })
    },

    initData() {
      if (this.data.campaign) {
        this.campaign = {
          ...this.data.campaign
        }
        this.brand = {
          ...this.data.brand
        }
      }

      this.useEinData = false
      this.orgForm = this.orgForms.find(item => item.value === this.brand.org_form)
      this.country = this.countries.find(item => item.value === this.brand.country)
      this.state = this.states.find(item => item.value === this.brand.state)
      this.vertical = this.verticals.find(item => item.value === this.brand.vertical)
    },

    onEinConfirm() {
      this.brand = {
        ...this.einData,
        status: this.brand.status,
      }
      if (this.einData.status === 'verified') {
        this.useEinData = true
      }
      this.einData = undefined
      this.showEinModal = false
    },

    onEinCancel() {
      this.einData = undefined
      this.useEinData = false
      this.showEinModal = false
      this.brand.brand_id = undefined
    },

    onEinChange() {
      this.brand.ein = this.brand.ein.replace(/[^0-9a-zA-Z ]/g, "")
      if (!this.brand.ein) {
        this.data.brand_id = null;
        this.brand.brand_id = null;
      } else {
        let params = {
          ein: this.brand.ein,
        }
        this.$store
          .dispatch('business/findBrandByEin', params)
          .then((data) => {
            if (data.data.brand_id) {
              this.einData = {
                ...data.data
              }
              this.showEinModal = true
            } else {
              this.data.brand_id = null;
              this.brand.brand_id = null;
            }
          })
      }
    },

    getRequestDetail(id) {
      this.loading = true
      this.$store
        .dispatch('admin/getA2pRequest', id)
        .then((data) => {
          this.data = {
            ...data.data
          }
          this.initData()
          this.loading = false
        })
        .catch(() => {
          this.loading = false
          this.$router.push({ name: 'admin.settings.a2p' })
        })
    },

    onUpdateClick() {
      if (this.brandStatus === 'unverified' || this.isCampaignDeclined) {
        this.isNew = false
      }
      this.showConfirmModal = true
    },

    onUpdate() {
      this.showConfirmModal = false
      this.processing = true

      this.brand.org_form = this.orgForm ? this.orgForm.value : null
      this.brand.country = this.country ? this.country.value : null
      this.brand.state = this.state ? this.state.value : null
      this.brand.vertical = this.vertical ? this.vertical.value : null

      let brandData = {
        ...this.brand,
        phone: this.formatRawNumber(this.brand.phone)
      }
      
      let brandUpdated = !_.isEqual(brandData, this.data.brand)
      let params = {
        brand: brandData,
        campaign: this.campaign,
        brand_updated: brandUpdated,
        id: this.data.id,
      }

      this.$store
        .dispatch('admin/updateA2pRequest', params)
        .then((data) => {
          this.processing = false
          this.getRequestDetail(this.data.id)
        })
        .catch(() => {
          this.processing = false
          this.getRequestDetail(this.data.id)
        })
    },
  },
}
</script>
  
<style lang="scss" scoped>
.admin-a2p-detail {
  .radio-wrapper {
    >label {
      display: inline-block;
      width: 625px;
    }

    .radio-group {
      display: inline-block;
    }
  }
}
</style>